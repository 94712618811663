/*Google fonts*/
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Rubik:500,700,900&display=swap');
/* Google Tifinagh font*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Tifinagh&display=swap');

:root{
  --p-c : #1888c8;
  --s-c : #2F6F94;
  --s-c-transparent: rgba(47, 111, 148,0.2);
  --t-c : #325061;
  --f-c : #252E33;
  --sel-c : #345B72;
  --p-txt-c : #093047;
  --s-txt-c : #082B3F;
  --p-bg-c : #f7f7f7;
  --p-bg-c-transparent: #f7f7f780;
  --s-bg-c : #f9f9f9;
  --t-bg-c : #DDDDDD;
  --bg-cir : rgba(255, 255, 255, 0.2);
}

[data-theme="dark"]{
  --sel-c :#9EB9C8;
  --p-txt-c : #def3ff;
  --s-txt-c: #C0DFF1;
  --p-bg-c : #1a1a1a;
  --p-bg-c-transparent: #1a1a1a80;
  --s-bg-c : #0a0a0a;
  --t-bg-c : #2e2e2e;
  --bg-cir : rgba(10, 10, 10, 0.2);
}

*{
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

::-moz-selection {
  background-color: var(--p-c);
  color: var(--sel-c);
}

::selection {
  background-color: var(--p-c);
  color: var(--sel-c);
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: var(--p-bg-c);
}

::-webkit-scrollbar-thumb {
  border-radius:5px;
  background: var(--p-c); 
}

::-webkit-scrollbar-thumb:hover {
  background: var(--s-c); 
}

body{
    margin: 0;
    line-height: 1.5;
    font-size:16px;
    font-family: 'Montserrat','Rubik','Noto Sans Tifinagh',sans-serif;
    font-weight: 400;
    background-color: var(--p-bg-c);
    height: 100vh;
}

/* Menu */

  .row,
  .column {
    box-sizing: border-box;
    display: flex;
    transition: all 400ms ease-in-out;
  }
  
  .row {
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    height: 50%;
  }
  
  .row.onHover {
    height: 70%;
  }
  
  .row.onHover > .column.onHover > div {
    padding: 14px 24px 24px 24px;
    font-size: 24px;
  }
  
  .row.offHover {
    height: 30%;
  }
  
  .row.active {
    height: 100%;
  }
  
  .row.active > .column.show > .ct {
    bottom: 0px;
    height: 80px;
    width: 80px;
    background: transparent;
  }
  
  .row.off {
    height: 0%;
  }
  
  .column {
    display: inline-flex;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }
  
  .column.onHover {
    flex-basis: 60%;
  }
  
  .column.offHover {
    flex-basis: 40%;
  }
  
  .column.show {
    flex-basis: 100%;
  }
  
  .column.hide {
    flex-basis: 0%;
  }
  
  .column.bg1,.column.bg2,.column.bg3,.column.bg4 {
    background: var(--p-bg-c);
  }
  
  .ct {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 200ms ease-out;
    padding: 24px;
    cursor: pointer;
    height: 100%;
    width: 100%;
    font-size: 12px;
  }
  
  .ct label{
    font-weight: 800;
    color: var(--p-c);
  }
  .row.active .column.show .ct label{
    display: none;
  }
  .row.active .ct{
    align-items: flex-start;
  }
  
  .bg1.show .ct label,.bg2.show .ct label,.bg3.show .ct label,.bg4.show .ct label{
    display: none;
  }
  
  .common {
    white-space: nowrap;
    position: absolute;
    background: var(--t-c);
    color: var(--t-c);
  }
  
  .colA,.colB,.colC,.colD{
    position: absolute;
    top: 0;
  }
  
  .colA,.colD  {
    background: var(--s-bg-c);
  }
  
  .colB,.colC {
    background: var(--t-bg-c);
  }